<template>
  <div class="container-fluid contain">
    <div style="display: flex; flex-direction: row; align-items: center">
      <img src="@/assets/images/icon_arrow_left.png" alt="" style="width: 18px; height: 15px; cursor: pointer;"
           @click="$router.go(-1)"/>
      <div style="margin-left: 10px;">
        <div class="product-title">{{product.name}}</div>
      </div>
    </div>
    <div class="row mt-4" style="margin-bottom: 180px;">
      <div class="col-lg-3 col-mg-12">
        <!--				<img :src="get(product, 'images[0].url')" style="width: 100%">-->
        <img :src="get(product, 'images[0].url')" style="width: 100%;">
        <button class="btn share" type="button" @click="onClickShare">SHARE THIS</button>
      </div>
      <div class="col-lg-6 col-mg-12 description-margin">
        <span class="desc-title">DESCRIPTION</span>
        <div style="margin-top: 10px; margin-bottom: 40px;">
          <span class="desc-content">{{product.description}}</span>
        </div>
        <span class="desc-title" style="padding-top: 30px;">PICK INGREDIENTS</span>
        <a class="ingredient-view" v-for="(ingredient, index) in modifiers" :key="index" @click="onSetModifier(index)">
          <div
            style="width: 20px; height: 20px; color: black; border-radius: 5px;  text-align: center; margin-right: 15px;"
            :style="`background-color: ${indexes.findIndex(i => i === index) > -1 ? '#E2E2E2' : 'black'}`">
            <img src="@/assets/images/icon_feather_check.png" style="margin-bottom: 2px;"
                 v-if="indexes.findIndex(i => i === index) === -1">
          </div>
          <div style="font-size: 13px; font-weight: 500; line-height: 32px; letter-spacing: 0.1px;"
               :style="`color: ${indexes.findIndex(i => i === index) > -1 ? '#E2E2E2' : 'black'}`">
            {{get(ingredient, 'inventoryProduct.ingredient.name')}}
          </div>
        </a>
        <div v-if="!isEvent" class="hidden-mobile">
          <div class="divider"></div>
          <div class="desc-title" style="margin-top: 20px; margin-bottom: 20px;">PICK ADD-ONS</div>
          <div class="row">
            <div class="col-xl-6 col-lg-12" v-for="(addon, index) in addOns" :key="index" style="padding-right: 0;">
              <div class="addon-view">
                <div class="d-flex flex-row align-items-start w-75">
                  <div style=" margin-right: 20px;" class="d-flex align-self-center w-25">
                    <img :src="get(addon, 'images[0].url')" class="img-fluid" style="height: 70px; object-fit: contain;"
                         v-if="get(addon, 'images[0].url')">
                    <div style="height: 60px; width: 75px; background-color: lightgrey" v-else></div>
                  </div>
                  <div class="d-flex flex-column align-items-start justify-content-start w-75">
                    <div style="font-weight: bold; font-size: 13px; letter-spacing: 0.1px; color: #808080">
                      {{addon.name}}
                    </div>
                    <div
                      style="font-weight: 500; font-size: 10px; line-height: 12px; letter-spacing: 0.1px; color: #828282">
                      ${{get(addon, 'pricing[0].retailPrice')}} each
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row align-items-center justify-content-around align-self-end"
                     style="width: 90px !important; height: 27px; border: 1px solid #f2f2f2; box-sizing: border-box; border-radius: 5px; margin-right: 5px;">
                  <a style="font-size: 14px; font-weight: 600; line-height: 17px;"
                     @click="onChangeAddOnQuantity(addon.id, false)">-</a>
                  <a style="font-size: 14px; font-weight: 600; line-height: 17px;">{{addon.quantity}}</a>
                  <a style="font-size: 14px; font-weight: 600; line-height: 17px;"
                     @click="onChangeAddOnQuantity(addon.id, true)">+</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-mg-12">
        <div style="display: flex; flex-direction: column; align-items: center">
          <span class="price">${{get(product, 'pricing[0].retailPrice', 0)}}</span>

          <div class="row justify-content-center align-items-center mt-1 mb-2">
            <img src="@/assets/images/icon_truck.png">
            <div style="font-weight: 500; font-size: 13px; line-height: 16px;" class="ml-1">Delivery Included</div>
          </div>

          <div class="row justify-content-center align-items-center mb-3">
            <img src="@/assets/images/icon_clock.png">
            <div style="font-weight: 500; font-size: 13px; line-height: 16px;" class="ml-1">
              Order by 6pm for next day delivery
            </div>
          </div>

          <div
            class="btn product-right-view-width share justify-content-center align-items-center mb-2 mt-4 product-right-view-width">
            <div class="deliver-btn">{{isEvent ? 'CHOOSE DATE': 'DELIVER BY'}}</div>
            <datepicker v-model="deliverBy" format="D, MMMM d, yyyy"
                        :disabled-dates="disabled"
                        style="text-align: center; padding-top: 5px;"
                        ref="dp"
            >
            </datepicker>
            <div style="position:absolute;" @click="$refs.dp.showCalendar()"><img
              src="@/assets/images/icon_arrow_down.png" style="width: 30%; margin-top: -35px;"></div>
          </div>
          <div class="d-flex flex-column float-left mt-3 mb-3 product-right-view-width"
               v-if="isEvent && moment(deliverBy).format('YYYY-MM-DD') === '2021-01-17'">
            <span style="font-weight: 600; font-size:14px; line-height: 17px;">CHOOSE:</span>
            <a class="d-flex flex-row justify-content-between align-items-center mt-3"
               v-for="(choice, index) in choices" :key="index" @click="onSelectChoice(index)">
              <div class="d-flex flex-row">
                <div
                  style="width: 20px; height: 20px; color: black; border-radius: 5px;  text-align: center; margin-right: 15px;"
                  :style="`background-color: ${index !== selectedIndex ? '#E2E2E2' : 'black'}`">
                  <img src="@/assets/images/icon_feather_check.png" style="margin-bottom: 2px;"
                       v-if="index === selectedIndex">
                </div>
                <span style="font-weight: 500; font-size: 13px;  letter-spacing: 0.108333px;"
                      :style="`color: ${index !== selectedIndex ? '#6D6D6D' : 'black'}`">{{choice.name}}</span>
              </div>
              <div
                style="width: 14px; height: 14px; border-radius: 7px; background-color: #c30000; font-size: 9px; line-height: 11px; color: white;"
                class="d-flex justify-content-center align-items-center" v-tooltip="'THIS IS TOOLTIP'">?
              </div>
            </a>
          </div>
          <div class="add-cart flex-row product-right-view-width" style="display: flex">
            <button class="btn btn-count">
              <div class="flex-row">
                <dropdown-menu v-model="show" :right="false" class="product-count">
                  <a class="dropdown-toggle">
                    <a
                      style="width: 40px; justify-content: space-between; align-items: center; display: flex; flex-direction: row;">
                      <div style="color: white;">{{quantity}}</div>
                      <img src="@/assets/images/icon_down_white.png">
                    </a>
                  </a>
                  <div slot="dropdown">
                    <a class="dropdown-item" @click="selectQuantity(item)" v-for="(item, index) in quantities"
                       :key="index">{{item}}</a>
                  </div>
                </dropdown-menu>
              </div>
            </button>
            <a class="add-btn d-flex justify-content-center align-items-center" @click="handleAddToCart"
               v-if="get(state, 'currentUser.id')">
              <div class="loader" v-if="state.isLoading"></div>
              <div v-else>ADD TO CART</div>
            </a>
            <a class="add-btn" data-toggle="modal" data-target="#loginModal" v-else>ADD TO CART</a>
          </div>

          <div v-if="!isEvent" class="show-mobile">
            <div class="divider"></div>
            <div class="desc-title" style="margin-top: 20px; margin-bottom: 20px;">PICK ADD-ONS</div>
            <div class="row">
              <div class="col-xl-6 col-lg-12" v-for="(addon, index) in addOns" :key="index" style="padding-right: 0;">
                <div class="addon-view">
                  <div class="d-flex flex-row align-items-start w-75">
                    <div style=" margin-right: 20px;" class="d-flex align-self-center w-25">
                      <img :src="get(addon, 'images[0].url')" class="img-fluid"
                           style="height: 70px; object-fit: contain;" v-if="get(addon, 'images[0].url')">
                      <div style="height: 60px; width: 75px; background-color: lightgrey" v-else></div>
                    </div>
                    <div class="d-flex flex-column align-items-start justify-content-start w-75">
                      <div style="font-weight: bold; font-size: 13px; letter-spacing: 0.1px; color: #808080">
                        {{addon.name}}
                      </div>
                      <div
                        style="font-weight: 500; font-size: 10px; line-height: 12px; letter-spacing: 0.1px; color: #828282">
                        ${{get(addon, 'pricing[0].retailPrice')}} each
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center justify-content-around align-self-end"
                       style="width: 90px !important; height: 27px; border: 1px solid #f2f2f2; box-sizing: border-box; border-radius: 5px; margin-right: 5px;">
                    <a style="font-size: 14px; font-weight: 600; line-height: 17px;"
                       @click="onChangeAddOnQuantity(addon.id, false)">-</a>
                    <a style="font-size: 14px; font-weight: 600; line-height: 17px;">{{addon.quantity}}</a>
                    <a style="font-size: 14px; font-weight: 600; line-height: 17px;"
                       @click="onChangeAddOnQuantity(addon.id, true)">+</a>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <DeliverTo :updateAddress="updateAddress" :selectedAddress="selectedAddress"/>
    <Share :route="$route"/>
  </div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api'
  import {json} from 'overmind';
  import {get} from 'lodash';
  import Datepicker from 'vuejs-datepicker';
  import DeliverTo from "./modals/DeliverTo";
  import moment from 'moment';
  import Share from "./modals/Share";
  import DropdownMenu from '@innologica/vue-dropdown-menu';
  import {Settings} from '../../settings';
  import $ from 'jquery';

  export default {
    components: {
      Datepicker,
      DeliverTo,
      Share,
      DropdownMenu
    },
    metaInfo() {
      const title = this.state.title;
      return {
        title
      }
    },
    mounted() {
      this.isModal = Settings.flow === 'modal';
      if (this.$route.query?.event === 'hush') {
        this.deliverBy = new Date('2021-1-17, 00:00:00');
      } else {
        this.deliverBy = new Date() > new Date(new Date().setHours(18)) ? new Date(new Date().setDate(new Date().getDate() + 2)) : new Date(new Date().setDate(new Date().getDate() + 1))
      }
      const addOns = json(this.state.product.addOns);
      for (let i = 0; i < addOns.length; i++) {
        addOns[i].quantity = 0
      }
      this.addOns = addOns;
    },
    computed: {
      isEvent() {
        return this.$route.query.event === 'hush';
      },
    },
    setup(props, {root}) {
      const products = Object.values(json(root.state.site.sites))[0]?.products;
      const currentProduct = products.find(p => p?.id === root.$route.query.id);
      const modifiers = currentProduct.tasks.filter(t => t.inventoryProduct);
      const data = reactive({
        quantities: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        product: currentProduct,
        addOns: [],
        modifiers,
        quantity: 1,
        deliverBy: new Date() > new Date(new Date().setHours(18)) ? new Date(new Date().setDate(new Date().getDate() + 2)) : new Date(new Date().setDate(new Date().getDate() + 1)),
        indexes: [],
        selectedAddress: null,
        disabled: {to: new Date() > new Date(new Date().setHours(18)) ? new Date(new Date().setDate(new Date().getDate() + 1)) : new Date()},
        show: false,
        choices: [
          {id: 0, name: 'Delivery'},
          {id: 1, name: 'Drive Up'},
          {id: 2, name: 'All-Inclusive Brunch (Outdoor)'},
          {id: 3, name: 'Brunch + Private After hour'},
        ],
        selectedIndex: 0,
      });

      const selectQuantity = (item) => {
        data.quantity = item;
      };

      const onCreateCart = async () => {
        // if ((root.state.currentUser?.paymentMethods || [])?.filter(p => p.type === 'payment' && p.isValid)?.length === 0) {
        //   $('#addPaymentModal').modal('show');
        //   return;
        // }
        if (!root.state.currentCart?.id) {
          if (data.isModal) {
            $('#deliverModal').modal('show');
            return;
          } else {
            root.$router.push({path: '/address'})
          }

        }
        let address = await localStorage.getItem('famous_jerk_offerAddress');
        address = JSON.parse(address);
        if (!address?.place_id && !root.state.currentCart?.id) {
          root.actions.alert.showError({message: 'Please enter a delivery address'});
          json(root.state.addressRef).focus();
          return false;
        }

        const itemIndex = items?.findIndex(i => i?.product?.id === productId && moment(data.deliverBy).format('dddd, MMMM D, YYYY') === i.formatDeliverBy);
        await root.actions.startLoading();
        const modifiers = [];
        const modifiersForEvent = [];
        data.modifiers.map((m, index) => {
          if (data.indexes.findIndex(i => i === index) > -1) {
            modifiers.push({id: m.id});
            modifiersForEvent.push('No ' + m?.inventoryProduct?.ingredient?.name);
          }
        });
        let saveCartParams = null;
        let items = json(root.state.currentCart?.items);
        items?.map(i => i.formatDeliverBy = moment(i.deliverBy).format('dddd, MMMM D, YYYY'));
        const isEvent = root.$route.query.event === 'hush';
        const productId = isEvent && data.selectedIndex === 2 ? '5ea38374a7b11b0007f6ea94' : data.product.id;
        try {
          if (itemIndex > -1) {
            let updateItems = [{
              quantity: root.state.currentCart?.items[itemIndex]?.quantity + data.quantity,
              productId,
              deliverBy: data.deliverBy,
              modifiers: modifiers,
              deliverTo: {id: root.state.currentCart?.items[0]?.deliverTo?.id},
              notes: isEvent ? (data.product?.name + ' ' + data.choices[data.selectedIndex].name + (modifiersForEvent.length > 0 ? (' with ' + modifiersForEvent.join(', ')) : '')) : null,
              id: root.state.currentCart?.items[itemIndex]?.id
            }];

            !isEvent && data.addOns.map(o => o.quantity > 0 && updateItems.push({
              quantity: o.quantity,
              productId: o.id,
              deliverBy: data.deliverBy,
              deliverTo: {id: root.state.currentCart?.items[0]?.deliverTo?.id},
            }));
            saveCartParams = {updateItems, cartId: root.state.currentCart?.id};
          } else if (root.state.currentCart?.id) {
            let addItems = [{
              quantity: data.quantity,
              productId,
              deliverBy: data.deliverBy,
              modifiers: modifiers,
              notes: isEvent ? (data.product?.name + ' ' + data.choices[data.selectedIndex].name + (modifiersForEvent.length > 0 ? (' with ' + modifiersForEvent.join(', ')) : '')) : null,
              deliverTo: {id: root.state.currentCart?.items[0]?.deliverTo?.id},
            }];

            !isEvent && data.addOns.map(o => o.quantity > 0 && addItems.push({
              quantity: o.quantity,
              productId: o.id,
              deliverBy: data.deliverBy,
              deliverTo: {id: root.state.currentCart?.items[0]?.deliverTo?.id},
            }));
            saveCartParams = {addItems, cartId: root.state.currentCart?.id};
          } else {
            let addItems = [{
              quantity: data.quantity,
              productId,
              deliverBy: data.deliverBy,
              modifiers: modifiers,
              notes: isEvent ? (data.product?.name + ' ' + data.choices[data.selectedIndex].name + (modifiersForEvent.length > 0 ? (' with ' + modifiersForEvent.join(', ')) : '')) : null,
              googlePlacesId: address?.place_id
            }];

            !isEvent && data.addOns.map(o => o.quantity > 0 && addItems.push({
              quantity: o.quantity,
              productId: o.id,
              deliverBy: data.deliverBy,
              googlePlacesId: address?.place_id
            }));
            saveCartParams = {addItems};
          }
          console.log(saveCartParams);
          await root.actions.cart.saveCart(saveCartParams);
          await root.$router.push({path: '/cart', query: root.$route.query?.event === 'hush' ? {event: 'hush'} : {}})
        } catch (e) {
          console.log(e);
        } finally {
          await root.actions.finishLoading();
        }
      };

      const handleAddToCart = async () => {
        await onCreateCart();
      };

      const onChangeAddOnQuantity = (id, isAdd) => {
        if (isAdd) {
          data.addOns.find(a => a.id === id).quantity = data.addOns.find(a => a.id === id).quantity + 1;
        } else {
          if (data.addOns.find(a => a.id === id).quantity === 0) return;
          data.addOns.find(a => a.id === id).quantity = data.addOns.find(a => a.id === id).quantity - 1;
        }
      };
      const onSetModifier = (index) => {
        const id = data.indexes.findIndex(i => i === index);
        if (id > -1) {
          data.indexes.splice(id, 1);
        } else {
          data.indexes.push(index);
        }
      };

      const updateAddress = async () => {
        await root.actions.startLoading();
        const modifiers = [];
        const modifiersForEvent = [];
        data.modifiers.map((m, index) => {
          if (data.indexes.findIndex(i => i === index) > -1) {
            modifiers.push({id: m.id});
            modifiersForEvent.push('No ' + m?.inventoryProduct?.ingredient?.name);
          }
        });
        let saveCartParams = null;
        let items = json(root.state.currentCart?.items);
        items?.map(i => i.formatDeliverBy = moment(i.deliverBy).format('dddd, MMMM D, YYYY'));
        const isEvent = root.$route.query.event === 'hush';
        const productId = isEvent && data.selectedIndex === 2 ? '5ea38374a7b11b0007f6ea94' : data.product.id;
        const address = json(root.state.currentUser.sites[root.state.currentUser.sites?.length - 1]);
        try {
          let addItems = [{
            quantity: data.quantity,
            productId,
            deliverBy: data.deliverBy,
            modifiers: modifiers,
            notes: isEvent ? data.product?.name + (modifiersForEvent.length > 0 ? (' with ' + modifiersForEvent.join(', ')) : '') : null,
            googlePlacesId: address?.googlePlacesId
          }];

          !isEvent && data.addOns.map(o => o.quantity > 0 && addItems.push({
            quantity: o.quantity,
            productId: o.id,
            deliverBy: data.deliverBy,
            googlePlacesId: address?.googlePlacesId
          }));
          saveCartParams = {addItems};
          console.log(saveCartParams);
          await root.actions.cart.saveCart(saveCartParams);
          await root.$router.push({path: '/cart', query: root.$route.query?.event === 'hush' ? {event: 'hush'} : {}})
        } catch (e) {
          console.log(e);
        } finally {
          await root.actions.finishLoading();
        }
      };
      const onSelectChoice = (index) => {
        data.selectedIndex = index;
      };
      const onClickShare = () => {
        if (data.isModal) {
          $('#shareModal').modal('show');
        } else {
          root.$router.push('/share');
        }
      };
      return {
        handleAddToCart,
        onChangeAddOnQuantity,
        onSetModifier,
        updateAddress,
        get,
        onSelectChoice,
        selectQuantity,
        moment,
        onClickShare,
        ...toRefs(data)
      }
    }
  }
</script>

<style lang="css" scoped>

  .deliver-btn {
    background-color: white;
    color: #010101;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    padding: 0 8px;
    margin: -11px 45px 0 45px;
    text-align: center;
    z-index: 1;
  }

  .addon-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    padding: 15px 2px 15px 10px;
    height: 90px;
  }

  .divider {
    height: 9px;
    width: 101%;
    background-color: #ededed;
    margin-top: 40px;
  }

  .ingredient-view {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  select {
    border: 0;
    outline: 0;
    padding: 0;
    background-color: #c30000;
  }

  .desc-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
  }

  .desc-content {
    font-size: 15px;
    line-height: 25px;
    color: #9f9999;
  }

  .price {
    font-size: 30px;
    font-weight: bold;
    line-height: 50px;
  }

  .add-cart {
    height: 45px;
    background-color: #c30000;
    width: 100%;
    border-radius: 5px;
    margin-top: 5px;
  }

  .btn-count {
    border-right-width: 1px;
    border-right-color: #8A0101;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
  }

  .product-count {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: white;
  }

  .add-btn {
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin: auto;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    color: white;
  }

  .show-mobile {
    display: none;
  }

  .share {
    border: 1px solid black;
    height: 45px;
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
    line-height: 17px;
    color: #010101;
  }

  .product-right-view-width {
    width: 80%;
  }

  .product-title {
    margin-left: 10px;
    font-weight: bold;
    font-size: 35px;
    line-height: 43px;
  }

  @media (max-width: 1025px) {
    .product-title {
      margin-left: 10px;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;
    }

    .description-margin {
      margin-top: 20px;
    }

    .hidden-mobile {
      display: none !important;
    }

    .show-mobile {
      display: block;
    }

    .product-right-view-width {
      width: 100%;
    }
  }


</style>
